import { BuilderProActions } from '@/store/builder-pro/types';
import _cloneDeep from 'lodash/cloneDeep';
import axios from 'axios';

const BASE_URL = process.env.VUE_APP_CONSTRUCTOR_2_BASE_URL;

const localeActions: Partial<BuilderProActions> = {
  async addLocale(id, toLang) {
    const fromLang: string | undefined = this.activeLocale;

    if (id) {
      if (!this.epackData.components[toLang]) {
        const components = _cloneDeep(this.epackData.components);
        components[toLang] = _cloneDeep(this.epackData.components[fromLang] || {
          master_template: {
            'index.html': []
          }
        });

        if (components[toLang]) {
          Object.keys(components[toLang]).forEach(templateKey => {
              if (templateKey !== 'master_template') {
                  delete components[toLang][templateKey];  
              }
          });
        }
        //Changing direction text for description in Arabic and Hebrew
        const isHebrew = toLang.endsWith("_he");
        const isArabic = toLang.endsWith("_ar");
        components[toLang]['master_template'][this.activePage].forEach((component: any) => {
          if(component.props && component.props.direction){
            if (!component.props.direction.value) {
              component.props.direction.value = {};
            }
            component.props.direction.value.desktop = isHebrew || isArabic ? "rtl" : "ltr";
        }});
        
        const manifest = this.epackData.manifest;
        manifest.data[toLang] = {};
        manifest.data[toLang].minisite = {};
        manifest.data[toLang].minisite.master_template = { contentTypes: ['html', 'json', 'iframe'] };
        this.epackData.manifest = manifest;
        this.epackData.components = components;
        this.onEpackDataUpdate({
          addToUpdated: {
            locale: toLang,
          }
        });
      }
    } else {
      if (fromLang && id) {
        await this.createSection({
          id,
          fromLang,
          toLang,
          fromTemplate: 'master_template',
          toTemplate: 'master_template'
        })
      } else {
        throw new Error('Something went wrong');
      }
    }

    this.activeLocale = toLang;
    const fromTemplate = this.epackData.components[fromLang]?.['master_template'] ? 'master_template' : Object.keys(this.epackData.components[fromLang])[0];
    this.activeTemplate = fromTemplate;
    this.activePage = this.epackData.components[fromLang]?.[fromTemplate]?.['index.html']
      ? 'index.html'
      : Object.keys(this.epackData.components[fromLang]?.[fromTemplate] || {})[0];
  },

  removeLocaleLocal(_locale) {
    const components = _cloneDeep(this.epackData.components);
    if (Object.keys(components).length > 1) {
      delete components[_locale];
      if (this.activeLocale === _locale) {
        this.activeLocale = components['ru'] ? 'ru' : Object.keys(components)[0];
      }
      const manifest = this.epackData.manifest;
      delete manifest.data[_locale];
      this.epackData.components = components;
      this.epackData.manifest = manifest;
      this.resetHistoryMulti({ locale: _locale })
      this.onEpackDataUpdate({addToUpdated: false, epackSavedInBackend: true});
    }
  },

  async removeLocale({ locale: _locale, epackId }) {
    return new Promise((resolve, reject) => {
      if (epackId) {
        axios
          .put(
            `${BASE_URL}/api/constructor/epackages/${epackId}/components`,
            {
              lang: _locale,
              template: null,
              page: null,
              components: null,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
              },
            }
          )
          .then((res) => {
            this.removeLocaleLocal(_locale);
            resolve(res);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      } else {
        this.removeLocaleLocal(_locale);
        resolve(true);
      }
    });
  },

  changeLocale(_locale) {
    this.activeLocale = _locale;
    this.activeTemplate = 'master_template';
    this.activePage = 'index.html';
    this.setActiveComponent({
      component: null,
    });
  },
};

export { localeActions };
