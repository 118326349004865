import { TComponent } from '@/store/builder-pro/types';
import { COMMON_PROPS } from '../../props';
import { EComponents } from '../../components';
import { EComponentTypes } from "../../types";

export const FRIENDLY_VK_VIDEO: TComponent = {
  componentKey: EComponents.FRIENDLY_VK_VIDEO,
  name: EComponents.FRIENDLY_VK_VIDEO,
  displayName: "VK Video",
  type: EComponentTypes.FEATURE,
  props: {
    vkOid: {
      name: 'VK OID(first part)',
      defaultValue: '-22822305',
    },
    vkId: {
      name: 'VK ID(second part)',
      defaultValue: '456241052',
    },
    backgroundColor: COMMON_PROPS.backgroundColor,
    borderRadius: COMMON_PROPS.borderRadius,
  },
};
