import { TComponent } from '@/store/builder-pro/types';
import {COMMON_PROPS, TEXT_PROPS} from '../../props';
import { EComponents } from '../../components';
import { EComponentTypes } from "../../types";
import {FRIENDLY_VIDEO} from './video';
import {FRIENDLY_YOUTUBE} from './youtube';
import { FRIENDLY_RUTUBE } from './rutube';
import { FRIENDLY_VK_VIDEO } from './vk-video';

export const FRIENDLY_SLIDE_TEXT: TComponent = {
  componentKey: EComponents.FRIENDLY_SLIDE_TEXT,
  name: EComponents.FRIENDLY_SLIDE_TEXT,
  type: EComponentTypes.COMPONENT,
  noHover: true,
  noDynamic: true,
  noSave: true,
  noManualRemove: true,
  isBaseComponent: true,
  props: {
    content: {
      name: "Text",
      defaultValue: "Example text",
    },
    textAlign: TEXT_PROPS.textAlign,
    fontSize: {
      ...TEXT_PROPS.fontSize,
      defaultValue: '4',
      items: ['1', '2', '3', '4', '5', '6', '7']
    },
    fontFamily: TEXT_PROPS.fontFamily,
    fontWeight: TEXT_PROPS.fontWeight,
    fontStyle: TEXT_PROPS.fontStyle,
    backgroundColor: COMMON_PROPS.backgroundColor,
    color: TEXT_PROPS.color,
    direction: TEXT_PROPS.direction,
  }
};

export const FRIENDLY_SLIDE_YOUTUBE: TComponent = {
  componentKey: EComponents.FRIENDLY_SLIDE_YOUTUBE,
  name: EComponents.FRIENDLY_SLIDE_YOUTUBE,
  displayName: 'Youtube',
  type: EComponentTypes.COMPONENT,
  noManualRemove: true,
  noHover: true,
  noDynamic: true,
  noSave: true,
  isBaseComponent: true,
  props: {
    ...FRIENDLY_YOUTUBE.props,
    alignHorizontal: {
      name: "Align Horizontal",
      defaultValue: "center",
      items: ["flex-start", "center", "flex-end"]
    },
    alignVertical: {
      name: "Align Vertical",
      defaultValue: "center",
      items: ["flex-start", "center", "flex-end"]
    },
  },
};

export const FRIENDLY_SLIDE_RUTUBE: TComponent = {
  componentKey: EComponents.FRIENDLY_SLIDE_RUTUBE,
  name: EComponents.FRIENDLY_SLIDE_RUTUBE,
  displayName: 'Rutube',
  type: EComponentTypes.COMPONENT,
  noManualRemove: true,
  noHover: true,
  noDynamic: true,
  noSave: true,
  isBaseComponent: true,
  props: {
    ...FRIENDLY_RUTUBE.props,
    alignHorizontal: {
      name: "Align Horizontal",
      defaultValue: "center",
      items: ["flex-start", "center", "flex-end"]
    },
    alignVertical: {
      name: "Align Vertical",
      defaultValue: "center",
      items: ["flex-start", "center", "flex-end"]
    },
  },
};

export const FRIENDLY_SLIDE_VK_VIDEO: TComponent = {
  componentKey: EComponents.FRIENDLY_SLIDE_VK_VIDEO,
  name: EComponents.FRIENDLY_SLIDE_VK_VIDEO,
  displayName: 'VK Video',
  type: EComponentTypes.COMPONENT,
  noManualRemove: true,
  noHover: true,
  noDynamic: true,
  noSave: true,
  isBaseComponent: true,
  props: {
    ...FRIENDLY_VK_VIDEO.props,
    alignHorizontal: {
      name: "Align Horizontal",
      defaultValue: "center",
      items: ["flex-start", "center", "flex-end"]
    },
    alignVertical: {
      name: "Align Vertical",
      defaultValue: "center",
      items: ["flex-start", "center", "flex-end"]
    },
  },
};

export const FRIENDLY_SLIDE_VIDEO: TComponent = {
  componentKey: EComponents.FRIENDLY_SLIDE_VIDEO,
  name: EComponents.FRIENDLY_SLIDE_VIDEO,
  displayName: 'Video',
  type: EComponentTypes.COMPONENT,
  noManualRemove: true,
  noHover: true,
  noDynamic: true,
  noSave: true,
  isBaseComponent: true,
  props: {
    ...FRIENDLY_VIDEO.props,
    alignHorizontal: {
      name: "Align Horizontal",
      defaultValue: "center",
      items: ["flex-start", "center", "flex-end"]
    },
    alignVertical: {
      name: "Align Vertical",
      defaultValue: "center",
      items: ["flex-start", "center", "flex-end"]
    },
  },
};

export const FRIENDLY_SLIDE_PICTURE: TComponent = {
  componentKey: EComponents.FRIENDLY_SLIDE_PICTURE,
  name: EComponents.FRIENDLY_SLIDE_PICTURE,
  displayName: 'Picture',
  type: EComponentTypes.COMPONENT,
  noManualRemove: true,
  noHover: true,
  noDynamic: true,
  noSave: true,
  isBaseComponent: true,
  props: {
    src: {
      fileType: "image",
      name: "Image",
      defaultValue: {
        desktop: "/static/constructor/placeholders/1416x708.jpg",
        mobile: "/static/constructor/placeholders/900x900.jpg",
      },
    },
    backgroundColor: {
      name: "Background color",
      defaultValue: "",
    },
    borderRadius: {
      name: "Border radius",
      defaultValue: "",
    },
    alignHorizontal: {
      name: "Align Horizontal",
      defaultValue: "center",
      items: ["flex-start", "center", "flex-end"]
    },
    alignVertical: {
      name: "Align Vertical",
      defaultValue: "flex-start",
      items: ["flex-start", "center", "flex-end"]
    },
  },
};

export const FRIENDLY_SLIDE: TComponent = {
  componentKey: EComponents.FRIENDLY_SLIDE,
  name: EComponents.FRIENDLY_SLIDE,
  displayName: "Slide",
  type: EComponentTypes.COMPONENT,
  noDynamic: true,
  noHover: true,
  noSave: true,
  staticChildren: true,
  noManualRemoveChildren: true,
  possibleParents: [EComponents.FRIENDLY_SLIDER],
  possibleChildren: [EComponents.FRIENDLY_SLIDE_PICTURE],
  props: {
    backgroundColor: COMMON_PROPS.backgroundColor,
  },
  children: [
    {
      ...FRIENDLY_SLIDE_PICTURE,
    },
  ],
};

export const FRIENDLY_SLIDER: TComponent = {
  componentKey: EComponents.FRIENDLY_SLIDER,
  name: EComponents.FRIENDLY_SLIDER,
  displayName: "Slider",
  type: EComponentTypes.FEATURE,
  noDynamic: true,
  noParent: true,
  noHover: true,
  staticChildren: true,
  possibleChildren: [EComponents.FRIENDLY_SLIDE],
  props: {
    hide: COMMON_PROPS.hide,
    backgroundColor: COMMON_PROPS.backgroundColor,
    contentPosition: {
      name: 'Content position',
      defaultValue: 'top',
      items: ['top', 'bottom']
    },
    leftArrowSvgCode: {
      name: 'Svg-code of arrow (left)',
      defaultValue: '',
    },
    arrowColor: {
      name: 'Navigation Arrow Color (default only)',
      defaultValue: '#000000',
    },
    arrowWidth: {
      name: 'Navigation Arrow Width',
      defaultValue: '46px',
    },
    arrowHeight: {
      name: 'Navigation Arrow Height',
      defaultValue: '46px',
    },
    bulletColor: {
      name: 'Pagination Bullet Color',
      defaultValue: '#cccccc',
    },
    showBullets: {
      name: 'Enable pagination',
      defaultValue: 'on',
      items: ['on', 'off'],
    },
    activeBulletColor: {
      name: 'Pagination Bullet Color (active)',
      defaultValue: '#16c7dc',
    },
  },
  children: [
    {
      ...FRIENDLY_SLIDE,
    },
  ],
};
