import {TComponent} from "@/store/builder-pro/types/component";
import {TComponentProp} from "@/store/builder-pro/types/component-prop";
import {TFont} from "@/store/builder-pro/types/font";
import { EEpackTypes } from '@/components/builder-pro/shared/consts';

enum EHistoryStepActionType {
  ADD_COMPONENT = "addComponent",
  REMOVE_COMPONENT = "removeComponent",
  SAVE_CUSTOM_COMPONENT = "saveCustomComponent",
  REMOVE_CUSTOM_COMPONENT = "removeCustomComponent",
  REORDER_COMPONENT = "reorderComponent",
  ADD_RAW_COMPONENT = "addRawComponent",
  MAKE_COMPONENT_DYNAMIC = "makeComponentDynamic",
  REMOVE_DYNAMIC_DATA_COMPONENT = "removeDynamicDataComponent",
  ADD_TABLE_ROW = "addTableRow",
  UPDATE_COMPONENT_PROP = "updateComponentProp",
  UPDATE_FONT = "updateFont",
}

type THistoryStepActionAddRemoveComponent = {
  type:
    | EHistoryStepActionType.REMOVE_COMPONENT
    | EHistoryStepActionType.ADD_RAW_COMPONENT
    | EHistoryStepActionType.ADD_TABLE_ROW;
  components: TComponent[];
}

type THistoryStepActionAddComponent = Omit<THistoryStepActionAddRemoveComponent, 'type'> & {
  type: EHistoryStepActionType.ADD_COMPONENT;
  componentIdsWithIncreasedOrders: number[];
}

type THistoryStepActionReorderComponent = {
  type: EHistoryStepActionType.REORDER_COMPONENT;
  componentId: number;
  prevOrder: number;
  newOrder: number;
  prevParentId: number;
  newParentId: number;
  desiredRawParentChildrenWithoutDraggedComponent: TComponent[];
}

type THistoryStepActionUpdateComponentProp = {
  type: EHistoryStepActionType.UPDATE_COMPONENT_PROP;
  componentId: number;
  propKey: string;
  prevProp: TComponentProp;
  newProp: TComponentProp;
  prevUsedFonts: TFont[] | undefined;
  newUsedFonts: TFont[] | undefined;
}

type THistoryStepActionAddRemoveCustomComponent = {
  type:
    | EHistoryStepActionType.SAVE_CUSTOM_COMPONENT
    | EHistoryStepActionType.REMOVE_CUSTOM_COMPONENT;
  component: TComponent;
  orderIndex: number;
}

type THistoryStepActionUpdateFont = {
  type: EHistoryStepActionType.UPDATE_FONT;
  prevUsedFonts: TFont[] | undefined;
  newUsedFonts: TFont[] | undefined;
  components: TComponent[];
}

type THistoryStepActionMakeDynamicComponent = {
  type: EHistoryStepActionType.MAKE_COMPONENT_DYNAMIC;
  dynamicDataComponent: TComponent;
  componentId: number;
}

type THistoryStepActionRemoveDynamicComponent = {
  type: EHistoryStepActionType.REMOVE_DYNAMIC_DATA_COMPONENT;
  componentPairs: {
    dynamicComponent: TComponent;
    componentId: number;
  }[];
}

type THistoryStepAction = {
  type: EHistoryStepActionType;
} & (
  | THistoryStepActionAddRemoveComponent
  | THistoryStepActionAddComponent
  | THistoryStepActionReorderComponent
  | THistoryStepActionUpdateComponentProp
  | THistoryStepActionAddRemoveCustomComponent
  | THistoryStepActionMakeDynamicComponent
  | THistoryStepActionRemoveDynamicComponent
  | THistoryStepActionUpdateFont
);

type THistoryStep = THistoryStepAction[];

type THistoryItem = {
  position: number;
  lastChangeTs: number | null;
  steps: THistoryStep[];
};

type THistory = Record<string, THistoryItem>;

type TQueryParams = {
  search: string | undefined;
  perPage: number;
  type: EEpackTypes | undefined;
  page: number;
}

export type {
  THistory,
  THistoryItem,
  THistoryStep,
  THistoryStepAction,
  THistoryStepActionAddRemoveComponent,
  THistoryStepActionAddComponent,
  THistoryStepActionReorderComponent,
  THistoryStepActionUpdateComponentProp,
  THistoryStepActionAddRemoveCustomComponent,
  THistoryStepActionMakeDynamicComponent,
  THistoryStepActionRemoveDynamicComponent,
  THistoryStepActionUpdateFont,
  TQueryParams
}

export {
  EHistoryStepActionType
}
