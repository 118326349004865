import _cloneDeep from 'lodash/cloneDeep';
import { TState } from '@/store/builder-pro/types';
import { EEditorTabs } from './editor-tabs';
import { DEFAULT_EPACK_DATA } from './default-epack-data';
import { DEFAULT_FONTS } from './default-fonts';

export const DEFAULT_STATE: TState = {
  modalUnsavedChangesOnLeaveRedirectPath: null,
  allowedToLeavePage: true,
  loading: true,
  windowWidth: 0,
  windowHeight: 0,
  activeTab: EEditorTabs.EDITOR,
  activeLocale: 'ru',
  activeTemplate: 'master_template',
  activePage: 'index.html',
  activeSize: 'desktop',
  brandsList: [],
  files: [],
  localStates: {
    activeTabContentUniqueIds: {},
    lastSelectedTabGroup: 1,
  },
  // epack
  epackSavedInBackend: false,
  activeEpackage: null,
  epackData: _cloneDeep(DEFAULT_EPACK_DATA),
  // component
  activeComponent: null,
  activeComponentDynamicOrder: null,
  loadedComponents: [],
  updatedComponents: [],
  // data-source
  dataSources: [],
  // font
  availableFonts: _cloneDeep(DEFAULT_FONTS),
  // history
  history: {},
  // queryParams
  queryParams: {
    search: '',
    perPage: 10,
    type: undefined,
    page: 1
  }
};
